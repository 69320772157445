<ng-container *ngIf="userValidation$ | async as userValidation">
  <ng-container *ngIf="isOnActiveRoute('pattern')||isOnActiveRoute('causes')||isOnActiveRoute('editor')">
    <button matTooltip="Hilfe" [matTooltipShowDelay]="300" mat-icon-button class="menu_icon"
            (click)="overlayOpen = !overlayOpen" cdkOverlayOrigin
            #trigger="cdkOverlayOrigin">
      <mat-icon>help_outline</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      [cdkConnectedOverlayFlexibleDimensions]="true"
      [cdkConnectedOverlayLockPosition]="true"
      [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
      [cdkConnectedOverlayViewportMargin]="5"

      (backdropClick)="overlayOpen = false"
      (detach)="overlayOpen=false"
    >
      <div class="scrollableGroup">
        <table class="helpOverlay" cdkScrollable>

          <th></th>
          <th>Game Design Elements</th>
          <th>Game Design Misfits</th>

          <tr>
            <td>
              <mat-icon class="featureSymbol" [inline]="true">multiple_stop</mat-icon>
            </td>
            <td><b>KI-Methode 1:</b> Zeigt, welche Verbindungen die KI zwischen den selektierten Elementen empfiehlt
            </td>
            <td><b>KI-Methode 3:</b> Zeigt, welche Elemente die KI für die selektierten Misfits als Lösung empfiehlt.
            </td>
          </tr>
          <tr>
            <td>
              <mat-icon class="featureSymbol" [inline]="true">zoom_out_map</mat-icon>
            </td>
            <td><b>KI-Methode 2:</b> Zeigt, welche Verbindungen die KI zu neuen, noch unselektierten Elementen
              empfiehlt.
            </td>
            <td><b>KI-Methode 4:</b> Zeigt, auf welche Misfits die KI angesichts der selektierten Elemente hinweist.
            </td>
          </tr>
          <tr>
            <td>
              <mat-icon class="featureSymbol" [inline]="true">sort</mat-icon>
            </td>
            <td><b>Sortierung:</b> Sortiert die manuell selektierten bzw. die von der KI empfohlenen Elementkarten.
            </td>
            <td><b>Sortierung:</b> Sortiert die manuell selektierten bzw. die von der KI empfohlenen Misfitkarten.</td>
          </tr>
          <tr>
            <td>
              <mat-icon class="featureSymbol" [inline]="true">border_outer</mat-icon>
            </td>
            <td><b>Regelkreisphasen:</b> Gruppiert die Elementkarten nach den vier Regelkreisphasen (Auftrag, Aktion,
              Welt, Reaktion)
            </td>
            <td rowspan="3">
              <div class='box yellow'></div>
              <div>
                <b>&nbsp;Erweiterung:&nbsp;</b>KI-empfohlene Verbindungen zu neuen Elementen.
              </div>
              <div class='box green'></div>
              <div>
                <b>&nbsp;Verdichtung:&nbsp;</b>KI-empfohlene Verbindungen zu vorhandenen Elementen.
              </div>
              <div class='box blue'></div>
              <div>
                <b>&nbsp;Selektion:&nbsp;</b>Manuell selektierte Elemente bzw. Verbindungen.
              </div>
              <div class='box red'></div>
              <div>
                <b>&nbsp;Auflösung:&nbsp;</b>Manuell selektierte Misfit-Auflösung durch ein Element.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <mat-icon class="featureSymbol" [inline]="true">border_inner</mat-icon>
            </td>
            <td><b>Leitfragen:</b> Gruppiert die Elementkarten danach, auf welche Frage sie eine Antwort geben.</td>
          </tr>
          <tr>
            <td colspan="2">
              <button class="downloadButton" mat-stroked-button (click)="downloadAsset('WebApp_und_KI_Assistenz.pdf');">
                <mat-icon [inline]="true">download</mat-icon>
                Download: Ausführliche Anleitung
              </button>
            </td>
          </tr>

        </table>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
