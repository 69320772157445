import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {CardDeck} from '../../shared/card-deck';
import {ElementAttribution} from '../../shared/element-attribution';
import {MatSidenav} from '@angular/material/sidenav';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';
import {RecommendationService} from '../../design-recommender/shared/recommendation.service';
import {ElementAttributionCategory} from '../../shared/element-attribution-category';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {GameDesign} from '../../design-recommender/shared/game-design';

@Component({
  selector: 'em-pattern-attribution',
  templateUrl: './pattern-attribution.component.html',
  styleUrls: ['./pattern-attribution.component.css']
})
// TODO: Prüfen, ob die AttributionComponent auch für misfits funktioniert, da ja auch Misfits attributiert werden können im CMS
export class PatternAttributionComponent implements OnChanges, OnInit {

  @Input() cardDeck$: Observable<CardDeck>;
  @Input() elementAttributionId: number;
  @Input() subAttributionId: number;
  @Input() searchForm;
  @Input() rightSidenav: MatSidenav;

  @Input() dragStartDelay: number;

  private patternMap: Map<number, CardPattern>;
  private subCategoryItemMap = new Map<number, Map<number, CardPattern[]>>();

  gameDesign: GameDesign;

  recommendedGameDesign: RecommendedGameDesign;
  elementAttribution$: Observable<ElementAttribution>;
  subElementAttribution$: Observable<ElementAttribution>;
  recommendationSorting: boolean;

  constructor(private gs: GameDesignService,
              private rs: RecommendationService,
              private cs: CardStoreService) {
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.recommendedGameDesign = undefined;
        this.gameDesign = undefined;
        this.subCategoryItemMap = new Map<number, Map<number, CardPattern[]>>();
      }
    });
  }

  ngOnInit(): void {
    this.cardDeck$.subscribe(deck => {
      this.gs.initGameDesign(deck.id, deck.type);
      this.gameDesign = this.gs.getGameDesign();
      this.gs.getGameDesignBehaviorSubject().subscribe(gameDesign => {
        this.gameDesign = gameDesign;
      });

      // Fetch ElementAttribution Observable
      if (this.elementAttributionId > 0) {
        this.elementAttribution$ = this.cs.getElementAttribution(deck.id, this.elementAttributionId);
      }

      // Fetch SubElementAttribution Observable
      if (this.subAttributionId > 0) {
        this.subElementAttribution$ = this.cs.getElementAttribution(deck.id, this.subAttributionId);
      }

      // Changes in the game design should cause new recommendations
      if (!this.rs.checkIfRecommendationRequestIsPossible()) {
        this.rs.setGameDesignBehaviorSubject(this.gs.getGameDesignBehaviorSubject());
      }

      // Changes in the recommendation should change the recommended game design
      this.rs.getRecommendationBehaviorSubject().subscribe(recommendedGameDesign => {
        if (this.rs.hasRecommendation()) {
          this.recommendedGameDesign = recommendedGameDesign;
        } else {
          this.recommendedGameDesign = null;
        }
      });

      this.rs.getRecommendationSortingBehaviorSubject().subscribe(sort => {
        this.recommendationSorting = sort;
      });
    });
  }

  /**
   * Ensure that the cell styling is updated if cell pattern page is altered
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'subAttributionId') {
          this.cardDeck$.subscribe(deck => {
            // Fetch SubElementAttribution Observable
            if (this.subAttributionId > 0) {
              this.subElementAttribution$ = this.cs.getElementAttribution(deck.id, this.subAttributionId);
            } else {
              this.subElementAttribution$ = null;
            }
          });
          break;
        }
      }
    }
  }


  getSubAttributionCardPatterns(
    attributionCategory: ElementAttributionCategory,
    subAttributionCategory: ElementAttributionCategory,
    cardDeckPatterns: CardPattern[]): CardPattern[] {
    if (this.subCategoryItemMap.has(attributionCategory.id)) {
      if (this.subCategoryItemMap.get(attributionCategory.id).has(subAttributionCategory.id)) {
        return this.subCategoryItemMap.get(attributionCategory.id).get(subAttributionCategory.id);
      }
    }
    if (this.patternMap === undefined) {
      this.initCardDeckPatternMap(cardDeckPatterns);
    }
    const cardPatterns: CardPattern[] = [];
    for (const attributionCategoryCardPattern of this.getAttributionCardPatterns(attributionCategory, cardDeckPatterns)) {
      for (const subAttributionItem of subAttributionCategory.items) {
        if (subAttributionItem.elementId === attributionCategoryCardPattern.id) {
          cardPatterns.push(attributionCategoryCardPattern);
        }
      }
    }
    let subCategoryCardMap = new Map<number, CardPattern[]>();
    if (this.subCategoryItemMap.has(attributionCategory.id)) {
      subCategoryCardMap = this.subCategoryItemMap.get(attributionCategory.id);
    }
    subCategoryCardMap.set(subAttributionCategory.id, cardPatterns);
    this.subCategoryItemMap.set(attributionCategory.id, subCategoryCardMap);

    return cardPatterns;
  }

  getAttributionCardPatterns(attributionCategory: ElementAttributionCategory, cardDeckPatterns: CardPattern[]): CardPattern[] {
    if (this.patternMap === undefined) {
      this.initCardDeckPatternMap(cardDeckPatterns);
    }
    const cardPatterns: CardPattern[] = [];
    for (const attributionItem of attributionCategory.items) {
      if (this.patternMap.has(attributionItem.elementId)) {
        cardPatterns.push(this.patternMap.get(attributionItem.elementId));
      }
    }
    return cardPatterns;
  }

  private initCardDeckPatternMap(cardDeckPatterns: CardPattern[]) {
    this.patternMap = new Map<number, CardPattern>();
    for (const cp of cardDeckPatterns) {
      this.patternMap.set(cp.id, cp);
    }
  }

  isSubAttributionCategory(attributionCategory: ElementAttributionCategory,
                           subAttributionCategory: ElementAttributionCategory,
                           cardDeckPatterns: CardPattern[]): boolean {
    return this.getSubAttributionCardPatterns(attributionCategory, subAttributionCategory, cardDeckPatterns).length > 0;
  }
}
