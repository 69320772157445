import {Component, Input, OnInit} from '@angular/core';
import {UserValidation} from '../shared/user-validation';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';

@Component({
  selector: 'em-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.css']
})
export class HelpMenuComponent implements OnInit {

  @Input() userValidation$: Observable<UserValidation>;

  private routePath = '';
  overlayOpen = false;

  scrollStrategy: ScrollStrategy;

  constructor(
    private sso: ScrollStrategyOptions,
    private router: Router,
    private location: Location
  ) {
    this.scrollStrategy = sso.block();
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.routePath = this.location.path();
      } else {
        this.routePath = 'Home';
      }
    });
  }

  public isOnActiveRoute(routeName: string): boolean {
    return this.routePath.includes(routeName);
  }

  public downloadAsset(filename: string): boolean {
    window.open('../../assets/files/' + filename);
    return false;
  }
}
