<ng-container *ngIf="userValidation$ && isOnActiveRoute('editor')">
  <button matTooltip="Erweiterte Optionen" [matTooltipShowDelay]="300" mat-icon-button [matMenuTriggerFor]="menu" aria-label="editor extended options menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="resetEditor()">
      <mat-icon>delete_forever</mat-icon>
      <span>Editor zurücksetzen</span>
    </button>
    <button mat-menu-item (click)="openDownloadDialog()">
      <mat-icon>photo</mat-icon>
      <span>Kartennetz herunterladen</span>
    </button>
  </mat-menu>
</ng-container>
