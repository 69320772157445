<ng-container *ngIf="cardPattern && cardDeck">
  <div class="card_discussion_frontside">
    <div class="card_discussion_header">
      <table class="card_discussion_header">
        <tr>
          <td class="card_discussion_header_number">{{ cardPattern.cardNumber }}</td>
          <td class="card_discussion_header_title">{{ cardPattern.name }}</td>
          <td class="card_discussion_header_image">
            <img [src]="cardPattern.imageUrl" alt="Icon">
          </td>
        </tr>
      </table>
    </div>
    <div class="card_discussion_summary">
      {{ cardPattern.summary }}
    </div>
  </div>
  <ng-container class="customConnectListItem" *ngIf="sourceNotInCardWeb(cardPattern.id)">
    <div style="color: red; font-weight: bold; font-size: 16px; padding: 10px; word-wrap: break-word;">
      '{{cardPattern.name}}' befindet sich nicht
      im Editor. Bitte ziehen Sie die Karte erst in den Editor.
    </div>
  </ng-container>
  <div class="sidenav_container">
  <div class="patternListSmall" fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
    <ng-container *ngFor="let pattern of this.cardDeck.cardPatterns">
      <ng-container *ngIf="this.patternNotInCardCells(pattern.id)">
        <em-card-pattern-small class="customConnectionItem" [cardDeckId]="pattern.cardDeckId"
                               [cardDeckType]="cardPattern.cardDeckType"
                               [cardPatternId]="pattern.id" [customConnection]="true"
                               [sourceDeckType]="this.cardPattern.cardDeckType"
                               [sourcePatternId]="this.cardPattern.id"
                               [sourcePatternName]="this.cardPattern.name"></em-card-pattern-small>
      </ng-container>
    </ng-container>
  </div></div>
</ng-container>
