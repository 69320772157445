<div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
  <ng-container *ngIf="name == 'sideMenu'">
    <router-outlet name="sideMenu"></router-outlet>
  </ng-container>
  <ng-container *ngIf="name == 'patternConnection'">
    <router-outlet name="second"></router-outlet>
  </ng-container>
  <ng-container *ngIf="name == 'customConnections'">
    <router-outlet name="customConnections"></router-outlet>
  </ng-container>
</div>
