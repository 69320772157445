import {Component} from '@angular/core';
import { onMainContentChange } from './animations/animations';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CardWebConnectorService} from './shared/card-web-connector.service';
import {UserValidation} from './shared/user-validation';
import {CardStoreService} from './shared/card-store.service';

@Component({
  selector: 'em-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ onMainContentChange ]
})

export class AppComponent {
  name = 'Angular';
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private cwc: CardWebConnectorService,
    private cs: CardStoreService) {  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken }) => {
      // ...
    });
    this.oidcSecurityService.userData$.subscribe((userDataResult) => {
      if ( userDataResult != null) {
        if ( userDataResult.userData != null ) {
          const userName = userDataResult.userData.upn;
          const userToken = userDataResult.userData.PAT;

          const userValidation: UserValidation = {
            userName,
            userOrganisation: '',
            userToken,
            accessToken: 0,
            carddecks: []
          };

          const userValidation$ = this.cs.validateUser(userValidation);
          this.cs.emitUserLoginEvent(userValidation$);
        }
      }
    });
  }
}
