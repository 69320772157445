import {Component, Input, OnInit} from '@angular/core';

import {Observable} from 'rxjs';

import {CardPatternObstacleConnection} from '../../shared/card-pattern-obstacle-connection';
import {CardStoreService} from '../../shared/card-store.service';
import {ActivatedRoute} from '@angular/router';
import {DeckType} from '../../shared/card-deck';
import {CardWebService} from '../../shared/card-web.service';

@Component({
  selector: 'em-pattern-connections',
  templateUrl: './pattern-connections.component.html',
  styleUrls: ['./pattern-connections.component.css']
})

/**
 * Shows obstacle information (patterns) and problem information (misfits)
 *
 * Component is used in the right menue and retrieves all information
 * needed for the retrival of the obstacle/problem information via
 * the routing parameters (ids of carddeck, source and target, type of carddeck).
 * This information is retrieved on the ngOnInit method and passed to the
 * CardStoreService. The Result is saved in the observable
 * cardPatternObstacleConnection$ which is used in the html template to
 * show and layout all information about the obstacle (pattern->pattern) or
 * problem (misfit->pattern).
 */
export class PatternConnectionsComponent implements OnInit {


  cardPatternObstacleConnection$: Observable<CardPatternObstacleConnection>;

  cannotConnect = false;

  sourceIsNotInCardWeb = false;
  noFreeConnector = false;

  targetNotInReach = false;

  obstacleDeckId: number;

  constructor(
    private cs: CardStoreService,
    private route: ActivatedRoute,
    private cw: CardWebService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      queryParamMap => {
        this.obstacleDeckId = Number(queryParamMap.get('obstacledeckid'));

        this.cardPatternObstacleConnection$ = this.cs.getObstacleConnection(
          Number(queryParamMap.get('carddeckid')),
          DeckType[queryParamMap.get('carddecktype')],
          Number(queryParamMap.get('sourceid')),
          Number(queryParamMap.get('targetid')));

        // check if card has any restrictions for connections and display the corresponding error message
        if (!this.cw.hasCardWebElement(Number(queryParamMap.get('sourceid')))) {
          this.cannotConnect = true;
          this.sourceIsNotInCardWeb = true;
        } else if (!this.cw.hasFreeConnector(Number(queryParamMap.get('sourceid')), Number(queryParamMap.get('targetid')))) {
          this.cannotConnect = true;
          this.noFreeConnector = true;
        } else if (this.cw.hasCardWebElement(Number(queryParamMap.get('targetid')))
          && !this.cw.isTargetInReach(Number(queryParamMap.get('sourceid')), Number(queryParamMap.get('targetid')))) {
          this.targetNotInReach = true;
          this.cannotConnect = true;
        } else {
          // reset states, because they might still be true from previous views
          this.cannotConnect = false;
          this.noFreeConnector = false;
          this.sourceIsNotInCardWeb = false;
          this.targetNotInReach = false;
        }

      }
    );

  }
}
