import {Component, OnInit} from '@angular/core';
import {CardWebConnectorService} from '../../shared/card-web-connector.service';

@Component({
  selector: 'em-status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.css']
})
export class StatusDisplayComponent implements OnInit {

  status = 'Default';
  statusColor = 'rgba(209,209,209,0.49)';
  isError = false;

  constructor(private ccs: CardWebConnectorService) {
  }

  ngOnInit(): void {
    this.ccs.status$.subscribe(status => {
      console.log(status);
      this.status = status;
      this.determineStatusColor();
    });
  }

  private determineStatusColor() {
    switch (this.status) {
      case 'AutoSave verbunden':
        this.statusColor = '#4d8effcf';
        this.isError = false;
        break;
      default:
        this.isError = true;
        this.statusColor = 'rgba(255,18,60,0.88)';
    }
  }
}
