import { Component, OnInit } from '@angular/core';
import {CardWebToImageService} from '../shared/card-web-to-image.service';

@Component({
  selector: 'em-image-download-dialog',
  templateUrl: './image-download-dialog.component.html',
  styleUrls: ['./image-download-dialog.component.css']
})
export class ImageDownloadDialogComponent implements OnInit {
  selectedImageQuality = 'maxSize';
  transparentBackground = true;
  loading = false;

  constructor( private imageDownloadService: CardWebToImageService) { }

  ngOnInit(): void {
  }

  downloadImage(){
    this.loading = true;
    const reducedImageQuality = this.selectedImageQuality !== 'maxSize';
    this.imageDownloadService.downloadSVGImage(reducedImageQuality, this.transparentBackground).then(r => this.loading = false);
  }
}
