import {Pipe, PipeTransform} from '@angular/core';
import {CardCell} from '../../shared/card-cell';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesignRelation} from '../../design-recommender/shared/game-design-relation';
import {DeckType} from '../../shared/card-deck';
import {GameDesignElement} from '../../design-recommender/shared/game-design-element';

@Pipe({
  name: 'connectionsSort',
  pure: false
})
export class ConnectionsSortPipe implements PipeTransform {

  private static getScoreMapForElements(cells: CardCell[], recommendedGameDesign: RecommendedGameDesign, sourceId: number):
    Map<number, number> {
    const scoreMapped = new Map<number, number>();
    for (const cell of cells) {
      for (const relationScore of recommendedGameDesign.recommendedGameDesignRelationScores) {
        if (relationScore.recommendedGameDesignRelation.sourceId === sourceId) {
          if (!scoreMapped.has(relationScore.recommendedGameDesignRelation.targetId)
            && (cell.targetId === relationScore.recommendedGameDesignRelation.targetId
              && relationScore.recommendationScore > 0.06)) {
            scoreMapped.set(relationScore.recommendedGameDesignRelation.targetId, relationScore.recommendationScore);
          } else {
            if (scoreMapped.get(relationScore.recommendedGameDesignRelation.targetId) !== relationScore.recommendationScore
              && cell.targetId === relationScore.recommendedGameDesignRelation.targetId
              && relationScore.recommendationScore > 0.06) {
              scoreMapped.set(relationScore.recommendedGameDesignRelation.targetId, relationScore.recommendationScore);
            }
          }
        }
      }
    }
    for (const cc of cells) {
      if (!scoreMapped.has(cc.targetId)) {
        scoreMapped.set(cc.targetId, 0.01 / cc.cardNumber);
      }
    }
    return scoreMapped;
  }

  private static getScoreMapForProblems(cells: CardCell[], recommendedGameDesign: RecommendedGameDesign, sourceId: number):
    Map<number, number> {
    const scoreMapped = new Map<number, number>();
    for (const cell of cells) {
      for (const elementScore of recommendedGameDesign.recommendedGameDesignProblemRelationScores) {
        if (elementScore.recommendedGameDesignRelation.sourceId === sourceId) {
          if (!scoreMapped.has(elementScore.recommendedGameDesignRelation.targetId)
            && elementScore.recommendationScore > 0.06) {
            scoreMapped.set(elementScore.recommendedGameDesignRelation.targetId, elementScore.recommendationScore);
          } else {
            if ((scoreMapped.get(elementScore.recommendedGameDesignRelation.targetId) !== elementScore.recommendationScore)
              && elementScore.recommendationScore > 0.06) {
              scoreMapped.set(elementScore.recommendedGameDesignRelation.targetId, elementScore.recommendationScore);
            }
          }
        }
      }
    }
    for (const cc of cells) {
      if (!scoreMapped.has(cc.targetId)) {
        scoreMapped.set(cc.targetId, 0.01 / cc.cardNumber);
      }
    }
    return scoreMapped;
  }

  private static considerGameDesign(scoreMap: Map<number, number>, gameDesignRelations: GameDesignRelation[], sourceId: number) {
    for (const element of gameDesignRelations) {
      if (scoreMap.has(element.targetId) && sourceId === element.sourceId) {
        scoreMap.set(element.targetId, 2 + scoreMap.get(element.targetId));
      }
    }
    return scoreMap;
  }

  private static considerExtendedGameDesign(scoreMap: Map<number, number>, gameDesign: GameDesign, sourceId: number) {
    for (const element of gameDesign.gameDesignProblemRelations) {
      if (scoreMap.has(element.targetId) && sourceId === element.sourceId) {
        scoreMap.set(element.targetId, 10 + scoreMap.get(element.targetId));
      }
    }
    return scoreMap;
  }

  private static getPartOfGameDesign(cell: CardCell, gameDesignElements: GameDesignElement[]) {
    for (const element of gameDesignElements) {
      if (element.id === cell.targetId) {
        return cell.cardNumber;
      }
    }
    return 100 + cell.cardNumber;
  }

  transform(
    value: CardCell[],
    gameDesign: GameDesign,
    recommendedGameDesign: RecommendedGameDesign,
    sourceId: number,
    deckType: DeckType) {
    if (gameDesign === undefined) {
      return;
    }
    if (recommendedGameDesign !== undefined && recommendedGameDesign !== null) {
      let scoreMap = new Map<number, number>();
      switch (deckType) {
        case DeckType.pattern:
          scoreMap = ConnectionsSortPipe.getScoreMapForElements(value, recommendedGameDesign, sourceId);
          break;
        case DeckType.problem:
          scoreMap = ConnectionsSortPipe.getScoreMapForProblems(value, recommendedGameDesign, sourceId);
          break;
      }
      if (deckType === DeckType.pattern) {
        scoreMap = ConnectionsSortPipe.considerGameDesign(scoreMap, gameDesign.gameDesignRelations, sourceId);
      }
      if (deckType === DeckType.problem) {
        scoreMap = ConnectionsSortPipe.considerExtendedGameDesign(scoreMap, gameDesign, sourceId);
      }
      value.sort((a, b) => (
        scoreMap.get(a.targetId) > scoreMap.get(b.targetId) ? -1 :
          scoreMap.get(b.targetId) > scoreMap.get(a.targetId) ? 1 : 0));
    } else {

      if (deckType === DeckType.problem) {
        value.sort((a, b) => a.cardNumber > b.cardNumber ? 1 : b.cardNumber > a.cardNumber ? -1 : 0);
      } else {
        value.sort((a, b) => (
          //a.cardNumber > b.cardNumber ? 1 : b.cardNumber > a.cardNumber ? -1 : 0
          ConnectionsSortPipe.getPartOfGameDesign(a, gameDesign.gameDesignElements) > ConnectionsSortPipe.getPartOfGameDesign(b, gameDesign.gameDesignElements) ? 1
            : ConnectionsSortPipe.getPartOfGameDesign(b, gameDesign.gameDesignElements) > ConnectionsSortPipe.getPartOfGameDesign(a, gameDesign.gameDesignElements) ? -1 : 0
        ));
      }
    }
    return value;
  }
}
