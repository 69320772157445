<mat-sidenav-container class="sidenav-container" *ngIf="userValidation$ | async as userValidation">

  <mat-sidenav-content>
    <div class="itemAligner">
      <ng-container *ngIf="this.attribution>0 && deckType ==='pattern'">
        <em-pattern-attribution [searchForm]="searchForm"
                                [elementAttributionId]="this.attribution" [subAttributionId]="this.subAttribution"
                                [rightSidenav]="rightSidenav" [cardDeck$]="this.cardDeck$" [dragStartDelay]="this.dragStartDelay"></em-pattern-attribution>
      </ng-container>
      <ng-container *ngIf="this.attribution==0 && this.subAttribution>0 && deckType ==='pattern'">
        <!-- Swap attribution and subAttribution if only subAttribution is set-->
        <em-pattern-attribution [searchForm]="searchForm"
                                [elementAttributionId]="this.subAttribution" [subAttributionId]="this.attribution"
                                [rightSidenav]="rightSidenav" [cardDeck$]="this.cardDeck$" [dragStartDelay]="this.dragStartDelay"></em-pattern-attribution>
      </ng-container>
      <ng-container *ngIf="(this.attribution==0 && this.subAttribution==0) || deckType === 'causes' ">
        <ng-container *ngIf="cardDeck$ | async as cardDeck">

          <div id="defaultDeck"
               cdkDropList
               cdkDropListSortingDisabled
               cdkDropListConnectedTo="editor">
            <ng-container
              *ngFor="let cp of cardDeck.cardPatterns | defaultCardFilter: searchForm.value.search | deckSort: this.gameDesign: this.cardDeck.type: this.recommendedGameDesign: this.recommendationSorting">

              <div class="card-wrapper" cdkDrag [cdkDragStartDelay]="this.dragStartDelay" [cdkDragData]="{cp, cardDeck}">
                <em-pattern-card [rightSidenav]="rightSidenav"
                                 [cardPatternId]="cp.id" [cardDeckId]="cp.cardDeckId"
                                 [cardDeckType]="cardDeck.type"></em-pattern-card>
                <div *cdkDragPlaceholder></div>
              </div>

            </ng-container>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
