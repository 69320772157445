import {Injectable} from '@angular/core';
import {GameDesign} from './game-design';
import {GameDesignFactory} from './game-design-factory';
import {DeckType} from '../../shared/card-deck';
import {BehaviorSubject} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';
import {ConnectorMessageType} from '../../shared/connector-message.type';
import {ObjectExtractingServiceService} from '../../shared/object-extracting-service.service';


@Injectable({
  providedIn: 'root'
})
export class GameDesignService {

  private static readonly maxNumberOfRelations = 3;

  private gameDesign: GameDesign;

  private bs$: BehaviorSubject<GameDesign>;

  constructor(private cs: CardStoreService,
              private ccs: ObjectExtractingServiceService) { //TODO change name
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.gameDesign = undefined;
      }
    });

  }

  public getGameDesignBehaviorSubject(): BehaviorSubject<GameDesign> {
    this.checkIfGameDesignIsInitialized();

    if (this.bs$ === undefined) {
      this.bs$ = new BehaviorSubject<GameDesign>(this.getGameDesign());
    }
    return this.bs$;
  }

  public addGameDesignElement(id: number, isPositiveElement: boolean, name: string, deckType: DeckType) {
    if (!this.hasGameDesignPattern(id) && !this.hasGameDesignProblem(id)) {
      const gameDesignElement = GameDesignFactory.createGameDesignElement(id, isPositiveElement, name, deckType);
      switch (deckType) {
        case DeckType.pattern:
          this.gameDesign.gameDesignElements.push(gameDesignElement);
          break;
        case DeckType.problem:
        case DeckType.causes:
          this.gameDesign.gameDesignProblems.push(gameDesignElement);
          break;
        default:
          throw new Error('Unknown DeckType. Cannot add GameDesignElement.');
      }
      this.bs$.next(this.getGameDesign());

      // CALL WEBSOCKET CONNECTION
      this.ccs.handleAction({
        type: ConnectorMessageType.ADD_CARD_GAMEDESIGN,
        sourceId: id,
        sourceName: name,
        sourceDeckType: deckType
      });
    }
  }

  public getGameDesign(): GameDesign {
    this.checkIfGameDesignIsInitialized();
    return this.gameDesign;
  }

  /**
   * Checks if maximum inbound relations for the element are reached
   * @param elementId Id of the sourceElement
   */
  public checkMaximumNumberOfInboundRelations(elementId: number): boolean {
    this.checkIfGameDesignIsInitialized();
    for (const gameDesignElement of this.getGameDesign().gameDesignElements) {
      if (gameDesignElement.id === elementId) {
        let numberOfInboundRelations = 0;
        for (const gameDesignRelation of this.getGameDesign().gameDesignRelations) {
          if (gameDesignRelation.targetId === gameDesignElement.id) {
            numberOfInboundRelations++;
            if (numberOfInboundRelations >= GameDesignService.maxNumberOfRelations) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * Checks if maximum outbound relations for the element are reached
   * @param elementId Id of the sourceElement
   */
  public checkMaximumNumberOfOutboundRelations(elementId: number): boolean {
    this.checkIfGameDesignIsInitialized();
    for (const gameDesignElement of this.getGameDesign().gameDesignElements) {
      if (gameDesignElement.id === elementId) {
        let numberOfInboundRelations = 0;
        for (const gameDesignRelation of this.getGameDesign().gameDesignRelations) {
          if (gameDesignRelation.sourceId === gameDesignElement.id) {
            numberOfInboundRelations++;
            if (numberOfInboundRelations >= GameDesignService.maxNumberOfRelations) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public removeGameDesignElement(id: number) {
    // Remove all relations the element is the source or the target

    for (let i = this.gameDesign.gameDesignRelations.length - 1; i > -1; i--) {
      if (this.gameDesign.gameDesignRelations[i].sourceId === id || this.gameDesign.gameDesignRelations[i].targetId === id) {
        this.gameDesign.gameDesignRelations.splice(i, 1);
      }
    }

    // Remove the element
    let hasDeletedElement = false;
    for (let i = 0; i < this.gameDesign.gameDesignElements.length; i++) {
      if (this.gameDesign.gameDesignElements[i].id === id) {
        this.gameDesign.gameDesignElements.splice(i, 1);
        hasDeletedElement = true;
        break;
      }
    }

    if (hasDeletedElement) {
      // If the element is a pattern remove possible problemRelations which might point to the element
      for (let i = this.gameDesign.gameDesignProblemRelations.length - 1; i > -1; i--) {
        if (this.gameDesign.gameDesignProblemRelations[i].targetId === id) {
          this.gameDesign.gameDesignProblemRelations.splice(i, 1);
        }
      }
    } else {
      // If the element is a problem then remove the problem
      for (let i = 0; i < this.gameDesign.gameDesignProblems.length; i++) {
        if (this.gameDesign.gameDesignProblems[i].id === id) {
          this.gameDesign.gameDesignProblems.splice(i, 1);
          break;
        }
      }
      // Delete all selected problemRelations as well
      for (let i = this.gameDesign.gameDesignProblemRelations.length - 1; i > -1; i--) {
        if (this.gameDesign.gameDesignProblemRelations[i].sourceId === id) {
          this.gameDesign.gameDesignProblemRelations.splice(i, 1);
        }
      }
    }
    this.bs$.next(this.getGameDesign());

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.REMOVE_CARD_GAMEDESIGN,
      sourceId: id
    });
  }

  public elementIsPartOfGameDesignAsSource(sourceId: number) {
    for (const gameDesignRelationRaw of this.gameDesign.gameDesignRelations) {
      if (gameDesignRelationRaw.sourceId === sourceId) {
        return true;
      }
    }
    return false;
  }

  public elementIsPartOfGameDesignAsTarget(targetId: number) {
    for (const gameDesignRelationRaw of this.gameDesign.gameDesignRelations) {
      if (gameDesignRelationRaw.targetId === targetId) {
        return true;
      }
    }
    return false;
  }

  clearGameDesign() {
    this.gameDesign.gameDesignRelations.splice(0, this.gameDesign.gameDesignRelations.length);
    this.gameDesign.gameDesignElements.splice(0, this.gameDesign.gameDesignElements.length);
  }

  public removeGameDesignRelationItem(sourceId: number, targetId: number, relationItemId: number, obstaclePatternType: DeckType) {
    switch (obstaclePatternType) {
      case DeckType.pattern:
        for (let i = 0; i < this.gameDesign.gameDesignRelations.length; i++) {
          if (this.gameDesign.gameDesignRelations[i].sourceId === sourceId
            && this.gameDesign.gameDesignRelations[i].targetId === targetId
            && this.getGameDesign().gameDesignRelations[i].relationItemId === relationItemId) {
            this.gameDesign.gameDesignRelations.splice(i, 1);
          }
        }
        this.bs$.next(this.getGameDesign());
        break;
      case DeckType.problem:
        // First remove the relation from the gameDesignProblemRelations
        for (let i = 0; i < this.gameDesign.gameDesignProblemRelations.length; i++) {
          if (this.gameDesign.gameDesignProblemRelations[i].sourceId === sourceId
            && this.gameDesign.gameDesignProblemRelations[i].targetId === targetId
            && this.getGameDesign().gameDesignProblemRelations[i].relationItemId === relationItemId) {
            this.gameDesign.gameDesignProblemRelations.splice(i, 1);
          }
        }
        this.bs$.next(this.getGameDesign());
        break;
      case DeckType.causes:
        for (let i = 0; i < this.gameDesign.gameDesignCauseRelations.length; i++) {
          if (this.gameDesign.gameDesignCauseRelations[i].sourceId === sourceId
            && this.gameDesign.gameDesignCauseRelations[i].targetId === targetId
            && this.getGameDesign().gameDesignCauseRelations[i].relationItemId === relationItemId) {
            this.gameDesign.gameDesignCauseRelations.splice(i, 1);
          }
        }
        this.bs$.next(this.getGameDesign());
        break;
    }

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.DELETE_CONNECTION,
      sourceId,
      targetId,
      sourceDeckType: obstaclePatternType,
      relationId: relationItemId
    });
  }

  public hasGameDesignProblem(id: number): boolean {
    // Search for Problems
    for (const gameDesignElement of this.gameDesign.gameDesignProblems) {
      if (gameDesignElement.id === id) {
        return true;
      }
    }
    return false;
  }

  public hasGameDesignPattern(id: number): boolean {
    this.checkIfGameDesignIsInitialized();

    // Search for Patterns
    for (const gameDesignElement of this.gameDesign.gameDesignElements) {
      if (gameDesignElement.id === id) {
        return true;
      }
    }
    return false;
  }

  public hasGameDesignProblemRelation(sourceId: number, targetId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignProblemRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          return true;
        }
      }
    }
    return false;
  }

  public hasGameDesignCauseRelation(sourceId: number, targetId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignCauseRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          return true;
        }
      }
    }
    return false;
  }

  public hasGameDesignRelation(sourceId: number, targetId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          return true;
        }
      }
    }
    return false;
  }

  public hasGameDesignProblemRelationItem(sourceId: number, targetId: number, relationItemId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignProblemRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          if (gameDesignRelation.relationItemId === relationItemId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasGameDesignCauseRelationItem(sourceId: number, targetId: number, relationItemId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignCauseRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          if (gameDesignRelation.relationItemId === relationItemId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasGameDesignRelationItem(sourceId: number, targetId: number, relationItemId: number): boolean {
    this.checkIfGameDesignIsInitialized();

    for (const gameDesignRelation of this.gameDesign.gameDesignRelations) {
      if (gameDesignRelation.sourceId === sourceId) {
        if (gameDesignRelation.targetId === targetId) {
          if (gameDesignRelation.relationItemId === relationItemId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasGameDesign(): boolean {
    try {
      this.checkIfGameDesignIsInitialized();
      return true;
    } catch (e) {
      return false;
    }
  }

  private checkIfGameDesignIsInitialized() {
    if (this.gameDesign === undefined) {
      throw new Error('No game design initialized');
    }
  }

  /**
   * Initializes the Game Design and complements missing cardDeck and type if game design is already initialized
   * @param cardDeckId number Id of the cardDeck
   * @param cardDeckType DeckType Type of the cardDeck
   */
  public initGameDesign(cardDeckId: number, cardDeckType: DeckType) {
    if (!this.hasGameDesign()) {
      // TODO: get GameDesign from Server
      this.gameDesign = GameDesignFactory.createGameDesign(cardDeckId, cardDeckType);
    } else {
      switch (cardDeckType) {
        case DeckType.pattern:
          this.gameDesign.cardDeckPatternId = cardDeckId;
          break;
        case DeckType.problem:
        case DeckType.causes:
          this.gameDesign.cardDeckProblemId = cardDeckId;
          break;
        default:
          throw new Error('Cannot init game design due to unknown cardDeckType ' + cardDeckType);
      }
    }
  }

  loadGameDesignByServer(gameDesign: GameDesign) {
    if (!this.hasGameDesign()) {
      console.log(':::::::::::::::::::::NEW:::DESIGN');
      this.initGameDesign(gameDesign.cardDeckPatternId, DeckType.pattern);
      this.initGameDesign(gameDesign.cardDeckProblemId, DeckType.causes);
    }
    if (this.bs$ === undefined) {
      this.bs$ = new BehaviorSubject<GameDesign>(this.getGameDesign());
    }
    this.gameDesign = gameDesign;
    this.bs$.next(this.gameDesign);
  }

  public addGameDesignRelationItem(
    sourceId: number,
    sourceName: string,
    sourceDeckType: DeckType,
    targetId: number,
    targetName: string,
    targetDeckType: DeckType,
    relationItemId: number) {
    if (!this.hasGameDesignRelationItem(sourceId, targetId, relationItemId)
      && !this.hasGameDesignProblemRelationItem(sourceId, targetId, relationItemId)
      && !this.hasGameDesignCauseRelationItem(sourceId, targetId, relationItemId)) {
      const gameDesignRelation = GameDesignFactory.createGameDesignRelation(sourceId, sourceName, targetId, targetName, relationItemId);
      switch (sourceDeckType) {
        case DeckType.pattern:
          this.gameDesign.gameDesignRelations.push(gameDesignRelation);
          break;
        case DeckType.problem:
          this.gameDesign.gameDesignProblemRelations.push(gameDesignRelation);
          break;
        case DeckType.causes:
          this.gameDesign.gameDesignCauseRelations.push(gameDesignRelation);
          break;
        default:
          throw new Error('Unkown sourceDeckType. Cannot add GameDesignRelationItem.');
      }

      // Check if the source should be added to the game design as well
      if (!this.hasGameDesignPattern(sourceId) && !this.hasGameDesignProblem(sourceId)) {
        const gameDesignElement = GameDesignFactory.createGameDesignElement(sourceId, true, sourceName, sourceDeckType);
        switch (sourceDeckType) {
          case DeckType.pattern:
            this.gameDesign.gameDesignElements.push(gameDesignElement);
            break;
          case DeckType.problem:
          case DeckType.causes:
            this.gameDesign.gameDesignProblems.push(gameDesignElement);
            break;
          default:
            throw new Error('Unknown Decktype for sourceElement. Could not add GameDesignRelation.');
        }
      }

      // Check if the target should be added to the game design
      if (!this.hasGameDesignPattern(targetId) && !this.hasGameDesignProblem(targetId)) {
        const gameDesignElement = GameDesignFactory.createGameDesignElement(targetId, true, targetName, targetDeckType);
        switch (targetDeckType) {
          case DeckType.pattern:
            this.gameDesign.gameDesignElements.push(gameDesignElement);
            break;
          case DeckType.problem:
          case DeckType.causes:
            this.gameDesign.gameDesignProblems.push(gameDesignElement);
            break;
          default:
            throw new Error('Unknown Decktype for targetElement. Could not add GameDesignRelation.');
        }
      }

      this.bs$.next(this.getGameDesign());

      // CALL WEBSOCKET CONNECTION
      this.ccs.handleAction({
        type: ConnectorMessageType.ADD_CONNECTION,
        sourceId,
        sourceName,
        sourceDeckType,
        targetId,
        targetName,
        targetDeckType,
        relationId: relationItemId,
      });
    }
  }

  public removeAllConnections(cardId) {
    // Remove all relations the element is the source or the target

    for (let i = this.gameDesign.gameDesignRelations.length - 1; i > -1; i--) {
      if (this.gameDesign.gameDesignRelations[i].sourceId === cardId || this.gameDesign.gameDesignRelations[i].targetId === cardId) {
        this.gameDesign.gameDesignRelations.splice(i, 1);
      }
    }
  }

  public clearGameDesignProblems() {
    this.gameDesign.gameDesignProblemRelations.splice(0, this.gameDesign.gameDesignProblemRelations.length);
    this.gameDesign.gameDesignProblems.splice(0, this.gameDesign.gameDesignProblems.length);
  }
}
