<h1 mat-dialog-title>Kartennetz Herunterladen</h1>
<div class="dialog" mat-dialog-content>
  <mat-divider></mat-divider>
  <br>
  <h2>Optionen:</h2>
  <div class="options">
    <mat-slide-toggle [(ngModel)]="transparentBackground" [checked]="true">transparenter Hintergrund</mat-slide-toggle>
    <br>
    <mat-form-field>
      <h3>Bildqualität</h3>
      <mat-select [(ngModel)]="selectedImageQuality">
        <mat-option value="maxSize">Maximale Qualität</mat-option>
        <mat-option value="reducedSize">Optimierte Bildgröße</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions class="actions">
  <button [disabled]="loading" mat-button color="accent" mat-dialog-close>Abbrechen</button>
  <button mat-button color="primary" (click)="downloadImage()">Download</button>
</div>
<div class="progress-bar" *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

