import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserValidation} from '../shared/user-validation';
import {Location} from '@angular/common';
import {CardWebService} from '../shared/card-web.service';
import {GameDesignService} from '../design-recommender/shared/game-design.service';
import {CardWebToImageService} from '../shared/card-web-to-image.service';
import {MatDialog} from '@angular/material/dialog';
import {ImageDownloadDialogComponent} from '../image-download-dialog/image-download-dialog.component';
import {ObjectExtractingServiceService} from '../shared/object-extracting-service.service';
import {ConnectorMessageType} from '../shared/connector-message.type';

@Component({
  selector: 'em-extended-options',
  templateUrl: './extended-options.component.html',
  styleUrls: ['./extended-options.component.css']
})
export class ExtendedOptionsComponent implements OnInit {
  @Input() userValidation$: Observable<UserValidation>;

  private routePath = '';

  constructor(
    private router: Router,
    private location: Location,
    private cws: CardWebService,
    private gs: GameDesignService,
    private is: CardWebToImageService,
    private dialog: MatDialog,
    private oes: ObjectExtractingServiceService
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.routePath = this.location.path();
      } else {
        this.routePath = 'Home';
      }
    });
  }

  public isOnActiveRoute(routeName: string): boolean {
    return this.routePath.includes(routeName);
  }

  public resetEditor() {
    this.cws.clearCustomCardWeb();
    this.gs.clearGameDesign();
    this.gs.clearGameDesignProblems();
    this.oes.handleAction({
      type: ConnectorMessageType.RESET_EDITOR
    });
  }

  public openDownloadDialog() {
    this.dialog.open(ImageDownloadDialogComponent);
  }
}
