<mat-sidenav-container *ngIf="cardPattern$ | async as cardPattern; else loading">

  <mat-sidenav-content>
    <mat-card class="mat-elevation-z4" [ngClass]="{'problemStyles':cardPattern.cardDeckType === 'problem'}">
      <div class="card_pattern_frontside">
        <table class="card_table">
          <tbody>
          <tr>
            <td rowspan="2" class="card_cell_cardnumber" emAddedGameElement [elementId]="cardPattern.id">
              <div>{{ cardPattern.cardNumber }}</div>
            </td>
            <td class="card_cell"></td>
            <td class="card_cell_direction card_cell_direction_redux_up" role="button" style="cursor:default;"></td>
            <td class="card_cell"></td>
            <td class="card_cell">
              <em-element-add [cardPattern]="cardPattern"></em-element-add>
            </td>
          </tr>
          <tr>
            <td rowspan="4" colspan="3" class="card_cell_pic">
              <img class="img_card"
                   [src]="cardPattern.imageUrl" alt="Icon"
                   emAddedGameElement [elementId]="cardPattern.id">
            </td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell_direction card_cell_direction_redux_left" style="cursor:default;"></td>
            <td class="card_cell_direction card_cell_direction_redux_right" style="cursor:default;">&nbsp;</td>
          </tr>
          <tr>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td rowspan="2" colspan="5" class="card_cell_title" emAddedGameElement [elementId]="cardPattern.id">
              {{ cardPattern.name }}
            </td>
          </tr>
          <tr>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell_number_left_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_right_without_border"></td>
          </tr>
          <tr>
            <td class="card_cell_number_left_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_right_without_border"></td>
          </tr>
          <tr>
            <td class="card_cell_number_left_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_right_without_border"></td>
          </tr>
          <tr>
            <td class="card_cell_direction card_cell_direction_redux_left" role="button" id="sideMenuButton"
                (click)="openSideMenu('left')"
                [routerLink]="[{ outlets: { sideMenu: ['side-menu']}}]"
                emRecommendationIndicator [cells]="cardPattern.cardCells" [cardDeckId]="cardPattern.cardDeckId"
                [deckType]="cardPattern.cardDeckType" [patternId]="cardPattern.id"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_direction card_cell_direction_redux_right" role="button"
                (click)="openSideMenu('right')"
                [routerLink]="[{ outlets: { sideMenu: ['side-menu']}}]"
                emRecommendationIndicator [cells]="cardPattern.cardCells" [cardDeckId]="cardPattern.cardDeckId"
                [deckType]="cardPattern.cardDeckType" [patternId]="cardPattern.id"></td>
          </tr>
          <tr>
            <td class="card_cell_number_left_without_border_lastrow"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_direction card_cell_direction_redux_down" role="button"
                (click)="openSideMenu('bottom')"
                [routerLink]="[{ outlets: { sideMenu: ['side-menu']}}]"
                emRecommendationIndicator [cells]="cardPattern.cardCells" [cardDeckId]="cardPattern.cardDeckId"
                [deckType]="cardPattern.cardDeckType" [patternId]="cardPattern.id"></td>
            <td class="card_cell_number_middle_without_border"></td>
            <td class="card_cell_number_right_without_border"></td>
          </tr>
          </tbody>
        </table>
        <div class="card_frontside_text card_frontside_text_fix">
          <div class="category_attribution_text_center" emAddedGameElement [elementId]="cardPattern.id">
            {{ cardPattern.summary }}
          </div>
        </div>
      </div>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #loading>
  <div style="color:#999999">Karte wird geladen...</div>
</ng-template>

