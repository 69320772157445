import {forwardRef, Inject, Injectable} from '@angular/core';
import {CardWeb} from './card-web';
import {ConnectorMessage} from './connector-message';
import {BehaviorSubject} from 'rxjs';
import {CardWebConnectorService} from './card-web-connector.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectExtractingServiceService {

  private message$: BehaviorSubject<ConnectorMessage> = new BehaviorSubject<ConnectorMessage>(null);

  constructor() {
  }

  public handleAction(message: ConnectorMessage): void {
    this.message$.next(message);
  }

  public getMessageBehaviorSubject() {
    return this.message$.asObservable();
  }
}
