<ng-container *ngIf="cardPattern$ | async as cardPattern; else loading">
  <div class="patternCardSmall" [ngClass]="{'customConnectionItem':this.customConnection}">
    <div class="patternListItem">
      {{cardPattern.cardNumber}}
    </div>
    <div class="patternListItem">
      <img class="smallPatternIcon" src="{{cardPattern.imageUrl}}">
    </div>
    <div class="patternListItem">
      {{cardPattern.name}}
    </div>
    <ng-container *ngIf="customConnection == true">
      <ng-container class="customConnectListItem patternListItem" *ngIf="noFreeConnector">
        <div
          matTooltip="Es gibt keinen Platz mehr. Bitte verschieben Sie eine Karte oder suchen Sie eine andere Verbindung."
          [matTooltipShowDelay]="300" class="errorItem">
          <mat-icon class="errorIcon">grid_off</mat-icon>
        </div>
      </ng-container>
      <ng-container class="customConnectListItem patternListItem" *ngIf="targetNotInReach">
        <div
          matTooltip="'{{cardPattern.name}}' befindet sich nicht in Reichweite.
          Bitte verschieben Sie Die Karte zuerst auf ein Verbindungsfeld für '{{this.sourcePatternName}}'"
          [matTooltipShowDelay]="300" class="errorItem">
          <mat-icon class="errorIcon">pivot_table_chart</mat-icon>
        </div>
      </ng-container>
      <div class="customConnectListItem" [ngClass]="{'connectionSlider':!this.noFreeConnector && !this.targetNotInReach}">
        <em-custom-relation-add [obstacleConnection]="this.customObstacleConnection"
                                [obstacleItem]="this.customObstacleItem"
                                [cannotConnect]="this.cannotConnect"></em-custom-relation-add>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #loading>
  <div class="patternCardSmall">
    Wird geladen...
  </div>
</ng-template>
