import {Component, OnInit} from '@angular/core';
import {animateText, onSideNavChangeRight} from '../../animations/animations';
import {CardPattern} from '../../shared/card-pattern';
import {EditorService} from '../shared/editor.service';
import {CardDeck} from '../../shared/card-deck';
import {Observable} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';
import {RecommendationService} from '../../design-recommender/shared/recommendation.service';
import {CardWebService} from '../../shared/card-web.service';

@Component({
  selector: 'em-pattern-menu',
  templateUrl: './pattern-menu.component.html',
  styleUrls: ['./pattern-menu.component.css'],
  animations: [onSideNavChangeRight, animateText]
})
export class PatternMenuComponent implements OnInit {

  public sideNavStateRight = false;
  cardPattern: CardPattern;
  cardDeck: CardDeck;
  patternList$: Observable<CardPattern>[];
  gameDesign: GameDesign;
  recommendedGameDesign: RecommendedGameDesign;

  sourceIsNotInCardWeb = false;
  cardNeighbours: number[] = [];

  constructor(
    public es: EditorService,
    private cs: CardStoreService,
    private gs: GameDesignService,
    private rs: RecommendationService,
    private cw: CardWebService
  ) {
  }

  ngOnInit(): void {
    this.es.getConncectionParams().subscribe(response => {
      this.cardPattern = response;

      this.cardNeighbours = this.cw.getNeighbouringCards(response.id);

      this.sourceIsNotInCardWeb = !this.cw.hasCardWebElement(this.cardPattern.id);

      this.cs.getCardDeck(this.cardPattern.cardDeckId).subscribe(deck => {
        this.cardDeck = deck;
      });
    });
    this.gameDesign = this.gs.getGameDesign();
    this.gs.getGameDesignBehaviorSubject().subscribe(gameDesign => {
      this.gameDesign = gameDesign;
    });

    if (!this.rs.checkIfRecommendationRequestIsPossible()) {
      this.rs.setGameDesignBehaviorSubject(this.gs.getGameDesignBehaviorSubject());
    }

    this.rs.getRecommendationBehaviorSubject().subscribe(recommendedGameDesign => {
      if (this.rs.hasRecommendation()) {
        this.recommendedGameDesign = recommendedGameDesign;
      } else {
        this.recommendedGameDesign = null;
      }
    });
  }

  patternNotInCardCells(id: number) {
    return !this.cardPattern.cardCells.some(cell => cell.targetId === id) && !(this.cardPattern.id === id);
  }
}
