import {Injectable} from '@angular/core';
import {CardWeb} from './card-web';
import {BehaviorSubject} from 'rxjs';
import {CardStoreService} from './card-store.service';
import {DeckType} from './card-deck';
import {CardWebElement} from './card-web-element';
import {GameDesignService} from '../design-recommender/shared/game-design.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CardWebConnectorService} from './card-web-connector.service';
import {ConnectorMessageType} from './connector-message.type';
import {ObjectExtractingServiceService} from './object-extracting-service.service';

@Injectable({
  providedIn: 'root'
})
export class CardWebService {

  public cardWeb: CardWeb;
  public cw$: BehaviorSubject<CardWeb>;
  private gridSize = 50;
  private locationGrid: boolean[][];
  private connectionPreference: string;

  constructor(
    private cs: CardStoreService,
    private gs: GameDesignService,
    private snackBar: MatSnackBar,
    private ccs: ObjectExtractingServiceService,
  ) {
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.cardWeb = undefined;
      }
    });
    this.initLocationGrid();
  }

  public checkIfCustomCardWebIsInitialized() {
    if (this.cardWeb === undefined) {
      throw new Error('No custom cardweb initialized');
    } else {
      return true;
    }
  }

  public hasCustomCardWeb(): boolean {
    try {
      this.checkIfCustomCardWebIsInitialized();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getCustomCardWeb() {
    this.checkIfCustomCardWebIsInitialized();
    return this.cardWeb;
  }

  public clearCustomCardWeb() {
    this.cardWeb.cards.splice(0, this.cardWeb.cards.length);
    this.locationGrid.forEach(row => {
      row.forEach((_, j, array) => {
        array[j] = false;
      });
    });
  }

  /**
   * A two-dimensional array is created that contains all occupied positions on the grid.
   * This array helps for collision checks and can deliver basic information about cards and their neighbouring cells.
   * The array is a significant performance improvement compared to searching for positions in the data structure.
   */
  public initLocationGrid() {
    this.locationGrid = new Array(this.gridSize).fill(null).map(() => new Array(this.gridSize).fill(false));
  }

  public addCardToLocationGrid(gridX: number, gridY: number) {
    this.locationGrid[gridX][gridY] = true;
  }

  public removeCardFromLocationGrid(gridX: number, gridY: number) {
    this.locationGrid[gridX][gridY] = false;

  }

  public checkCardCollision(coordX: number, coordY: number) {
    return (this.checkCardOutOfBounds(coordX, coordY) ||
      this.locationGrid[this.calculateGridPositionX(coordX)][this.calculateGridPositionY(coordY, coordX)]);
  }

  public checkCardOutOfBounds(coordX: number, coordY: number): boolean {
    return (coordX < 0
      || coordY < 0
      || this.calculateGridPositionY(coordY, coordX) > this.gridSize
      || this.calculateGridPositionX(coordX) >= this.gridSize);
  }

  public changeCardLocationInLocationGrid(id: number, initialX: number, initialY: number, newX: number, newY: number) {
    const newXGrid = this.calculateGridPositionX(newX);
    const newYGrid = this.calculateGridPositionY(newY, newX);
    const initialYGrid = this.calculateGridPositionY(initialY, initialX);
    const initialXGrid = this.calculateGridPositionX(initialX);
    this.addCardToLocationGrid(newXGrid, newYGrid);
    this.removeCardFromLocationGrid(initialXGrid, initialYGrid);
    this.changeCardWebElementPosition(id, newXGrid, newYGrid);
  }

  public loadCustomCardWeb(id: number) {
    if (this.cardWeb) {
      return;
    }
    this.cardWeb = {
      id: 1,
      name: 'CustomDeckOne',
      cards: []
    };
    this.cardWeb.cards.forEach(card => {
      this.locationGrid[card.posX][card.posY] = true;
    });
    this.cw$ = new BehaviorSubject<CardWeb>(this.cardWeb);
  }

  /**
   * Overwrite CardWeb after server sends saved object
   */
  loadCardWebByServer(cardWeb: CardWeb) {
    console.log('::::::::::::::::::::LOAD CARDWEB');
    if (this.cardWeb) {
      this.cardWeb = cardWeb;
      this.cw$.next(this.cardWeb);
      return;
    }
    this.cardWeb = cardWeb;
    this.cw$ = new BehaviorSubject<CardWeb>(this.cardWeb);
    this.cardWeb.cards.forEach(cardWebElement => {
      this.addCardToLocationGrid(cardWebElement.posX, cardWebElement.posY);
    });
  }

  public loadCustomWebGameDesign() {
    this.cardWeb.cards.forEach(card => {
      this.gs.addGameDesignElement(card.id, true, card.name, card.cardDeckType);
    });
  }

  public calculateGridPositionX(coordinateX: number) {
    return coordinateX / 400;
  }

  public calculateGridPositionY(coordinateY: number, coordinateX: number) {
    if ((coordinateX) % 800 === 0) {
      return (coordinateY / 500) * 2;
    } else {
      // console.log('x: ' + coordinateX + 'y: ' + coordinateY + ' res: ' + (((coordinateY + 250) / 500) * 2 - 1));
      return (((coordinateY + 250) / 500) * 2 - 1);
    }
  }

  public hasCardWebElement(id: number): boolean {
    for (const card of this.cardWeb.cards) {
      if (card.id === id) {
        return true;
      }
    }
  }

  public addCardWebElement(
    id: number,
    name: string,
    cardDeckId: number,
    cardDeckType: DeckType,
    posX: number,
    posY: number) {
    if (!this.hasCardWebElement(id) && this.hasCustomCardWeb()) {
      const cardWebElement: CardWebElement = {
        id,
        name,
        cardDeckId,
        cardDeckType,
        posX,
        posY
        // posX: this.calculateGridPositionX(posX),
        // posY: this.calculateGridPositionY(posY, posX)
      };
      this.cardWeb.cards.push(cardWebElement);
      this.gs.addGameDesignElement(id, true, cardWebElement.name, cardDeckType);
      this.addCardToLocationGrid(cardWebElement.posX, cardWebElement.posY);
    }
    this.cw$.next(this.getCustomCardWeb());

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.ADD_CARD_CARDWEB,
      cardWebId: this.cardWeb.id,
      sourceId: id,
      sourceName: name,
      cardDeckId,
      sourceDeckType: cardDeckType,
      posX,
      posY
    });
  }

  removeCardWebElement(id: number) {
    this.cardWeb.cards.forEach((card, index) => {
      if (card.id === id) {
        this.cardWeb.cards.splice(index, 1);
        this.removeCardFromLocationGrid(card.posX, card.posY);
        this.gs.removeGameDesignElement(id);
        this.snackBar.open(card.name + ' und dazugehörige Verbindungen entfernt', '',
          {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 3000
          });
      }
    });
    this.cw$.next(this.getCustomCardWeb());

    // CALL WEBSOCKET CONNECTION
    this.ccs.handleAction({
      type: ConnectorMessageType.REMOVE_CARD_CARDWEB,
      cardWebId: this.cardWeb.id,
      sourceId: id
    });
  }

  public changeCardWebElementPosition(id: number, posX: number, posY: number) {
    if (this.hasCardWebElement(id)) {
      const cardIndex = this.cardWeb.cards.findIndex(card => card.id === id);
      if (cardIndex !== -1) {
        this.cardWeb.cards[cardIndex].posX = posX;
        this.cardWeb.cards[cardIndex].posY = posY;
        this.removeUntenableConnections(id, this.cardWeb.cards[cardIndex].name);
        console.log(posX, posY);
      }
      this.cw$.next(this.getCustomCardWeb());

      // CALL WEBSOCKET CONNECTION
      this.ccs.handleAction({
        type: ConnectorMessageType.MOVE_CARD,
        cardWebId: this.cardWeb.id,
        posX,
        posY
      });
    }
  }

  public getCustomCardWebBehaviorSubject(): BehaviorSubject<CardWeb> {
    this.checkIfCustomCardWebIsInitialized();
    if (this.cw$ === undefined) {
      this.cw$ = new BehaviorSubject<CardWeb>(this.getCustomCardWeb());
    }
    return this.cw$;
  }

  public addDroppedCard(
    id: number,
    name: string,
    cardDeckId: number,
    cardDeckType: DeckType,
    posX: number,
    posY: number) {
    const possiblePosX = Math.round((posX - 150) / 400) * 400;
    let possiblePosY = (Math.round((posY - 150) / 500) * 500);
    if (possiblePosX % 800 !== 0) {
      possiblePosY = possiblePosY - 250;
    }
    const possibleGridX = this.calculateGridPositionX(possiblePosX);
    const possibleGridY = this.calculateGridPositionY(possiblePosY, possiblePosX);

    if (this.locationGrid[possibleGridX][possibleGridY] === true) {
      const nearestFreeCell = this.calculateNearestFreeCell(this.locationGrid, possibleGridX, possibleGridY);
      this.addCardWebElement(id, name, cardDeckId, cardDeckType, nearestFreeCell.row, nearestFreeCell.column);
    } else {
      this.addCardWebElement(id, name, cardDeckId, cardDeckType, possibleGridX, possibleGridY);
    }
  }

  calculateNearestFreeCell(posMatrix: boolean[][], gridX: number, gridY: number): { row: number, column: number } | null {
    const rows = posMatrix.length;
    const cols = posMatrix[0].length;

    const queue: [number, number][] = [];
    const visited: boolean[][] = Array.from({length: rows}, () => Array(cols).fill(false));

    // Define directions for neighboring cells
    const directions: [number, number][] = [[-1, 0], [1, 0], [0, -1], [0, 1]];

    // Add the starting position to the queue
    queue.push([gridX, gridY]);
    visited[gridX][gridY] = true;

    while (queue.length > 0) {
      const nextPos = queue.shift();
      if (!nextPos) {
        continue;
      }

      const [r, c] = nextPos;

      // Check if the current position is free
      if (!posMatrix[r][c] && this.checkForValidCell(r, c)) {
        return {row: r, column: c};
      }

      // Explore the neighboring cells
      for (const [dr, dc] of directions) {
        const newRow = r + dr;
        const newCol = c + dc;

        // Check if the neighboring cell is within bounds and not visited
        if (newRow >= 0 && newRow < rows && newCol >= 0 && newCol < cols && !visited[newRow][newCol]) {
          queue.push([newRow, newCol]);
          visited[newRow][newCol] = true;
        }
      }
    }

    return null;
  }

  checkForValidCell(gridX: number, gridY: number): boolean {
    if (((gridX % 2 === 0) && (gridY % 2 === 0)) || ((gridX % 2 !== 0) && (gridY % 2 !== 0))) {
      return true;
    } else {
      return false;
    }
  }

  public canConnectLeft(posX: number, posY: number, sourceId: number) {
    if ((posX - 1 < 0) || (posY + 1 > this.gridSize)) {
      return false;
    } else {
      return !this.hasConnection('left', sourceId, posX, posY);
    }
  }

  public canConnectRight(posX: number, posY: number, sourceId: number) {
    if ((posX >= this.gridSize - 1) || (posY + 1 > this.gridSize)) {
      return false;
    } else {
      return !this.hasConnection('right', sourceId, posX, posY);
    }
  }

  public canConnectBottom(posX: number, posY: number, sourceId: number) {
    if ((posY + 2 > this.gridSize)) {
      return false;
    } else {
      return !this.hasConnection('bottom', sourceId, posX, posY);
    }
  }

  public hasConnection(direction: string, sourceId: number, posX: number, posY: number) {

    let hasConnection = true;
    switch (direction) {
      case 'left':
        if (this.locationGrid[posX - 1][posY + 1]) {
          const neighbouringCard = this.cardWeb.cards.find(card => card.posX === posX - 1 && card.posY === posY + 1);
          hasConnection = this.gs.hasGameDesignRelation(sourceId, neighbouringCard.id)
            || this.gs.hasGameDesignProblemRelation(sourceId, neighbouringCard.id);
        } else {
          hasConnection = false;
        }
        break;
      case 'right':
        if (this.locationGrid[posX + 1][posY + 1]) {
          const neighbouringCard = this.cardWeb.cards.find(card => card.posX === posX + 1 && card.posY === posY + 1);
          hasConnection = this.gs.hasGameDesignRelation(sourceId, neighbouringCard.id)
            || this.gs.hasGameDesignProblemRelation(sourceId, neighbouringCard.id);
        } else {
          hasConnection = false;
        }
        break;
      case'bottom':
        if (this.locationGrid[posX][posY + 2]) {
          const neighbouringCard = this.cardWeb.cards.find(card => card.posX === posX && card.posY === posY + 2);
          hasConnection = this.gs.hasGameDesignRelation(sourceId, neighbouringCard.id)
            || this.gs.hasGameDesignProblemRelation(sourceId, neighbouringCard.id);
        } else {
          hasConnection = false;
        }
        break;
    }
    return hasConnection;
  }

  public hasFreeConnector(sourceId: number, targetId: number) {
    const source = this.cardWeb.cards.find(card => card.id === sourceId);
    let result = true;
    if (this.canConnectRight(source.posX, source.posY, source.id)
      || this.canConnectLeft(source.posX, source.posY, source.id)
      || this.canConnectBottom(source.posX, source.posY, source.id)) {
      if (this.locationGrid[source.posX - 1][source.posY + 1]) {
        result = this.cardWeb.cards.find(card => card.posX === source.posX - 1 && card.posY === source.posY + 1).id === targetId;
      } else {
        result = true;
      }
      if (this.locationGrid[source.posX + 1][source.posY + 1]) {
        if (!result) {
          result = this.cardWeb.cards.find(card => card.posX === source.posX + 1 && card.posY === source.posY + 1).id === targetId;
        }
      } else {
        result = true;
      }
      if (this.locationGrid[source.posX][source.posY + 2]) {
        if (!result) {
          result = this.cardWeb.cards.find(card => card.posX === source.posX && card.posY === source.posY + 2).id === targetId;
        }
      } else {
        result = true;
      }
      return result;
    } else {
      return false;
    }
  }

  public getNewTargetCoord(sourceId: number, targetId: number) {
    const source = this.cardWeb.cards.find(card => card.id === sourceId);
    let x: number;
    let y: number;
    if (!this.connectionPreference) {
      this.connectionPreference = 'left';
    }
    switch (this.connectionPreference) {
      case ('left'):
        if (!this.locationGrid[source.posX - 1][source.posY + 1]) {
          x = source.posX - 1;
          y = source.posY + 1;
          return {x, y};
        } else if (!this.locationGrid[source.posX + 1][source.posY + 1]) {
          x = source.posX + 1;
          y = source.posY + 1;
          return {x, y};
        } else if (!this.locationGrid[source.posX][source.posY + 2]) {
          x = source.posX;
          y = source.posY + 2;
          return {x, y};
        }
        break;
      case ('right'):
        if (!this.locationGrid[source.posX + 1][source.posY + 1]) {
          x = source.posX + 1;
          y = source.posY + 1;
          return {x, y};
        } else if (!this.locationGrid[source.posX - 1][source.posY + 1]) {
          x = source.posX - 1;
          y = source.posY + 1;
          return {x, y};
        } else if (!this.locationGrid[source.posX][source.posY + 2]) {
          x = source.posX;
          y = source.posY + 2;
          return {x, y};
        }
        break;
      case ('bottom'):
        if (!this.locationGrid[source.posX][source.posY + 2]) {
          x = source.posX;
          y = source.posY + 2;
          return {x, y};
        } else if (!this.locationGrid[source.posX - 1][source.posY + 1]) {
          x = source.posX - 1;
          y = source.posY + 1;
          return {x, y};
        } else if (!this.locationGrid[source.posX + 1][source.posY + 1]) {
          x = source.posX + 1;
          y = source.posY + 1;
          return {x, y};
        }

    }
  }


  public removeUntenableConnections(cardId: number, cardName: string) {
    console.log(cardId + 'removing');
    const gameDesign = this.gs.getGameDesign();
    for (let i = gameDesign.gameDesignRelations.length - 1; i > -1; i--) {
      if (gameDesign.gameDesignRelations[i].sourceId === cardId || gameDesign.gameDesignRelations[i].targetId === cardId) {
        if (!this.isTargetInReach(gameDesign.gameDesignRelations[i].sourceId, gameDesign.gameDesignRelations[i].targetId)) {
          this.snackBar.open('Verbindungen zu ' + cardName
            + ' entfernt', '',
            {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 3000
            });
          this.gs.removeGameDesignRelationItem(
            gameDesign.gameDesignRelations[i].sourceId,
            gameDesign.gameDesignRelations[i].targetId,
            gameDesign.gameDesignRelations[i].relationItemId,
            DeckType.pattern);

          //gameDesign.gameDesignRelations.splice(i, 1);
        }
      }
    }
    for (let i = gameDesign.gameDesignProblemRelations.length - 1; i > -1; i--) {
      if (gameDesign.gameDesignProblemRelations[i].sourceId === cardId || gameDesign.gameDesignProblemRelations[i].targetId === cardId) {
        if (!this.isTargetInReach(gameDesign.gameDesignProblemRelations[i].sourceId, gameDesign.gameDesignProblemRelations[i].targetId)) {
          this.snackBar.open('Verbindungen zu ' + cardName
            + ' entfernt', '',
            {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 3000
            });
          this.gs.removeGameDesignRelationItem(
            gameDesign.gameDesignProblemRelations[i].sourceId,
            gameDesign.gameDesignProblemRelations[i].targetId,
            gameDesign.gameDesignProblemRelations[i].relationItemId,
            DeckType.problem);

          //gameDesign.gameDesignProblemRelations.splice(i, 1);
        }
      }
    }
  }

  public isTargetInReach(sourceId: number, targetId: number) {
    const sourceCard = this.cardWeb.cards.find(card => card.id === sourceId);
    const targetCard = this.cardWeb.cards.find(card => card.id === targetId);
    if ((targetCard.posY === sourceCard.posY + 1 && targetCard.posX === sourceCard.posX - 1)
      || (targetCard.posY === sourceCard.posY + 1 && targetCard.posX === sourceCard.posX + 1)
      || (targetCard.posY === sourceCard.posY + 2 && targetCard.posX === sourceCard.posX)) {
      return true;
    } else {
      return false;
    }
  }

  public setConnectionPreference(preference: ('left' | 'right' | 'bottom')) {
    this.connectionPreference = preference;
  }

  public getNeighbouringCards(id: number) {
    const neighbours: number[] = [];
    if (this.hasCardWebElement(id)) {
      try {
        let posX;
        let posY;
        const cardIndex = this.cardWeb.cards.findIndex(card => card.id === id);
        if (cardIndex !== -1) {
          posX = this.cardWeb.cards[cardIndex].posX;
          posY = this.cardWeb.cards[cardIndex].posY;
        }
        const neighbourLeft = this.cardWeb.cards.find(card => card.posX === posX - 1 && card.posY === posY + 1);
        const neighbourRight = this.cardWeb.cards.find(card => card.posX === posX + 1 && card.posY === posY + 1);
        const neighbourBottom = this.cardWeb.cards.find(card => card.posX === posX && card.posY === posY + 2);
        if (neighbourLeft !== undefined) {
          neighbours.push(neighbourLeft.id);
        }
        if (neighbourRight !== undefined) {
          neighbours.push(neighbourRight.id);
        }
        if (neighbourBottom !== undefined) {
          neighbours.push(neighbourBottom.id);
        }
      } catch (Error) {
        console.log(Error);
      }
    }
    return neighbours;
  }

  public getBoundingBox() {
    let topLeftX: number;
    let topLeftY: number;
    let bottomRightX: number;
    let bottomRightY: number;
    let minRow = this.gridSize;
    let minCol = this.gridSize;
    let maxRow = -1;
    let maxCol = -1;
    let foundTrue = false;

    for (let row = 0; row < this.gridSize; row++) {
      for (let col = 0; col < this.gridSize; col++) {
        if (this.locationGrid[row][col]) {
          if (!foundTrue) {
            foundTrue = true;
          } // Found at least one true value
          if (row < minRow) {
            minRow = row;
          }
          if (col < minCol) {
            minCol = col;
          }
          if (row > maxRow) {
            maxRow = row;
          }
          if (col > maxCol) {
            maxCol = col;
          }
        }
      }
    }

    if (foundTrue) {
      topLeftX = this.calculateCardXCoordinates(minRow) - 200;
      topLeftY = this.calculateCardYCoordinates(minCol, minRow) - 200;
      bottomRightX = this.calculateCardXCoordinates(maxRow) + 500;
      bottomRightY = this.calculateCardYCoordinates(maxCol, maxRow) + 500;

      const boundingBoxHeight = bottomRightY - topLeftY;
      const boundingBoxWidth = bottomRightX - topLeftX;

      console.log({x: topLeftX, y: topLeftY, height: boundingBoxHeight, width: boundingBoxWidth});
      return {x: topLeftX, y: topLeftY, height: boundingBoxHeight, width: boundingBoxWidth};
    } else {
      return {x: 0, y: 0, height: 100, width: 100};
    }
  }

  public calculateCardXCoordinates(gridPosX: number): number {
    return gridPosX * 400;
  }

  public calculateCardYCoordinates(gridPosY: number, gridPosX: number) {
    if ((gridPosX * 400) % 800 === 0) {
      return (gridPosY / 2) * 500;
    } else {
      return (((gridPosY - 1) / 2) * 500) + 250;
    }
  }
}
