import {Component, OnInit} from '@angular/core';
import {onSideNavChange, animateText} from '../animations/animations';
import {CardStoreService} from '../shared/card-store.service';
import {UserValidation} from '../shared/user-validation';
import {Observable} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CardWebConnectorService} from '../shared/card-web-connector.service';
import {CardWeb} from '../shared/card-web';
import {GameDesign} from '../design-recommender/shared/game-design';

interface Page {
  link: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'em-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
  animations: [onSideNavChange, animateText]
})
/**
 * The left menu provides access to all use cases.
 *
 * Use cases are
 * - Exploration of unlocked misfit carddeck with problem/solution description
 * - Exploration of unlocked pattern carddeck with obstacles descriptions
 *
 * Subscribes itself to the UserLoginListener via the CardStoreService to
 * show in the menu the links to the unlocked carddecks and functions.
 */
export class LeftMenuComponent implements OnInit {

  public sideNavState = true;
  public linkText = true;
  userValidation$: Observable<UserValidation>;
  cardWebs: { userid: string, cardWeb: CardWeb, gameDesign: GameDesign }[];


  public pages: Page[] = [
    {name: 'Home', link: 'home', icon: 'home'}
  ];

  constructor(
    private cs: CardStoreService,
    private oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.cs.getUserLoginListener().subscribe(b => {
      this.userValidation$ = b;
    });
  }

  logout(): void {
    // OIDC-Logout
    this.oidcSecurityService.logoff();
    // Traditional LogoutMethods
    this.cs.clearUserValidation();
    this.cs.clearUserData();
  }
}
