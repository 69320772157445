import {Component, Input} from '@angular/core';

@Component({
  selector: 'em-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.css']
})
export class StickyNoteComponent {
  @Input() header: string | undefined;
  @Input() text = '';
  @Input() backgroundColor = '#ffeb3b';
  @Input() width = 200;
  @Input() height = 150;
  @Input() expandedHeight = 300;

  expanded = false;

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
