import {Pipe, PipeTransform} from '@angular/core';
import {CardDeck} from '../../shared/card-deck';
import {CardPattern} from '../../shared/card-pattern';
import {CardWebService} from '../../shared/card-web.service';

@Pipe({
  name: 'defaultCardFilter',
  pure: false
})
export class DefaultCardFilterPipe implements PipeTransform {
  constructor(private cws: CardWebService) {
  }

  transform(
    value: CardPattern[],
    search: string
  ) {
    if (value) {
      return [
        ...value.filter((item) => {
          return (!this.cws.hasCardWebElement(item.id) &&
            (item.cardNumber.toString().includes(search) ||
              item.name.toLowerCase().includes(search.toLowerCase())));
        }),
      ];
    }
  }
}

// properties.some((property) => regexp.test(item[property]))
//      const regexp = new RegExp(search, 'i');
//       const properties = Object.keys(value[6]);
