import {Directive, ElementRef, Input} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {CardCell} from '../../shared/card-cell';
import {DeckType} from '../../shared/card-deck';
import {BackgroundColor} from '../../design-recommender/shared/recommendation-color.enum';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';
import {RecommendationService} from '../../design-recommender/shared/recommendation.service';
import {Observable} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';

@Directive({
  selector: '[emRecommendationIndicator]'
})
/**
 * This directive informs the card about recommendations for its cardcells and shows the best possible score
 */

export class RecommendationIndicatorDirective {

  @Input() cells: CardCell[];
  @Input() deckType: DeckType;
  @Input() patternId: number;
  @Input() cardDeckId: number;
  private currentPattern: CardPattern;
  private pattern$: Observable<CardPattern>;

  constructor(
    private el: ElementRef,
    private gs: GameDesignService,
    private rs: RecommendationService,
    private cs: CardStoreService
  ) {

    if (rs.checkIfRecommendationRequestIsPossible()) {
      /*this.pattern$ = cs.getPatternCardWithCell(this.cardDeckId, this.patternId, DeckType.pattern);
      this.pattern$.subscribe(pattern => {
          this.currentPattern = pattern;
        }
      );*/
      const rs$ = rs.getRecommendationBehaviorSubject();
      rs$.subscribe((recom) => {
        if (this.cells) {
          this.updateStyling(this.cells, recom);
        }
      });
    }
  }

  /*ngOnChanges(changes: SimpleChanges) {
    if (this.rs.checkIfRecommendationRequestIsPossible()) {
      this.updateStyling(this.currentPattern.cardCells);
    }
  }*/

  private updateStyling(cells: CardCell[], recom) {
    let topScore = 0;
    let type = '';
    for (const cell of cells) {
      if (this.rs.hasRecommendationForRelation(cell.sourceId, cell.targetId)) {
        const relationScore = this.rs.getRecommendedGameDesignRelationScore(cell.sourceId, cell.targetId);
        if (relationScore.recommendationScore > 0.06) {
          if (topScore < relationScore.recommendationScore) {
            topScore = relationScore.recommendationScore;
            type = relationScore.recommendationType;
          }
        }
      }
    }
    if (topScore !== 0) {
      this.styleElement(
        topScore,
        type
      );
    } else {
      this.styleElement(1, 'none');
    }
  }

  private styleElement(opacity: number, type: string) {
    if (type === 'EXTENSION') {
      this.el.nativeElement.style.borderColor = `rgba(255, 205, 0, ${opacity})`;
    }
    if (type === 'EXISTING') {
      this.el.nativeElement.style.borderColor = `rgba(4, 175, 118, ${opacity})`;
    }
    if (type !== 'none') {
      this.el.nativeElement.title = opacity;
    } else {
      this.el.nativeElement.style.borderColor = '#ddd';
      this.el.nativeElement.title = '';
    }
  }
}
