import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GameDesignService} from '../shared/game-design.service';
import {CardWebService} from '../../shared/card-web.service';
import {CardPatternObstacleConnection} from '../../shared/card-pattern-obstacle-connection';
import {CardPatternObstacleItem} from '../../shared/card-pattern-obstacle-item';

@Component({
  selector: 'em-custom-relation-add',
  templateUrl: './custom-relation-add.component.html',
  styleUrls: ['./custom-relation-add.component.css']
})
export class CustomRelationAddComponent implements OnInit {
  elementSelect = new FormControl(false);

  constructor(
    private gs: GameDesignService,
    private cw: CardWebService
  ) {
  }

  @Input() obstacleConnection: CardPatternObstacleConnection;
  @Input() obstacleItem: CardPatternObstacleItem;
  @Input() cannotConnect: boolean;

  ngOnInit(): void {
    if (this.gs.hasGameDesign()) {
      this.gs.getGameDesignBehaviorSubject().subscribe(() => {
        if (this.gs.hasGameDesignRelationItem(
            this.obstacleConnection.patternId,
            this.obstacleConnection.obstaclePatternId,
            this.obstacleItem.id)
          || this.gs.hasGameDesignProblemRelationItem(
            this.obstacleConnection.patternId,
            this.obstacleConnection.obstaclePatternId,
            this.obstacleItem.id)
          || this.gs.hasGameDesignCauseRelationItem(
            this.obstacleConnection.patternId,
            this.obstacleConnection.obstaclePatternId,
            this.obstacleItem.id)
        ) {
          if (!this.elementSelect.value) {
            this.elementSelect.setValue(true);
          }
        } else {
          if (this.elementSelect.value) {
            this.elementSelect.setValue(false);
          }
        }
      });
      if (!this.elementSelect.value) {
        if (this.isDisabled()) {
          this.elementSelect.disable();
        } else {
          this.elementSelect.enable();
        }
      }
    }
  }

  isDisabled() {
    // Prevent inverse relations as well
    if (this.cannotConnect) {
      return true;
    }

    return (this.gs.checkMaximumNumberOfInboundRelations(this.obstacleConnection.obstaclePatternId)
      || this.gs.checkMaximumNumberOfOutboundRelations(this.obstacleConnection.patternId)
      || this.gs.hasGameDesignRelation(this.obstacleConnection.obstaclePatternId, this.obstacleConnection.patternId)
      || this.gs.hasGameDesignProblemRelation(this.obstacleConnection.obstaclePatternId, this.obstacleConnection.patternId)
      || this.gs.hasGameDesignCauseRelation(this.obstacleConnection.obstaclePatternId, this.obstacleConnection.patternId));
  }

  changeElementSelect() {
    if (this.elementSelect.value) {
      if (!this.cw.hasCardWebElement(this.obstacleConnection.obstaclePatternId)) {
        const newTarget = this.cw.getNewTargetCoord(this.obstacleConnection.patternId, this.obstacleConnection.obstaclePatternId);
        this.cw.addCardWebElement(this.obstacleConnection.obstaclePatternId,
          this.obstacleConnection.obstaclePatternName,
          this.obstacleConnection.cardDeckId,
          this.obstacleItem.type,
          newTarget.x,
          newTarget.y
        );
      }
      this.gs.addGameDesignRelationItem(
        this.obstacleConnection.patternId,
        this.obstacleConnection.patternName,
        this.obstacleConnection.obstaclePatternType,
        this.obstacleConnection.obstaclePatternId,
        this.obstacleConnection.obstaclePatternName,
        this.obstacleItem.type,
        this.obstacleItem.id
      );
    } else {
      this.gs.removeGameDesignRelationItem(
        this.obstacleConnection.patternId,
        this.obstacleConnection.obstaclePatternId,
        this.obstacleItem.id,
        this.obstacleConnection.obstaclePatternType,
      );
    }
  }

}
