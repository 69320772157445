import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {EditorComponent} from './editor/editor.component';
import {PatternMenuComponent} from './pattern-menu/pattern-menu.component';
import {PatternConnectionsComponent} from './pattern-connections/pattern-connections.component';
import {PatternMenuCustomComponent} from './pattern-menu-custom/pattern-menu-custom.component';


const routes: Routes = [
  {
    path: ':carddeckid',
    component: EditorComponent,
    children: [
      {
        path: 'side-menu',
        component: PatternMenuComponent,
        outlet: 'sideMenu',
        children: [
          {
            path: 'pattern-connection',
            component: PatternConnectionsComponent,
            outlet: 'second'
          },
          {
            path: 'custom-connections',
            component: PatternMenuCustomComponent,
            outlet: 'customConnections'
          }
        ]
      }
    ],
    canActivate: [AutoLoginPartialRoutesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GraphEditorRoutingModule {
}
