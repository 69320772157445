import {Directive, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[emDraggablePatternCard]'
})
export class DraggablePatternCardDirective {
@Input() draggedCard: any;
  constructor() { }
  @HostBinding('draggable')
  get draggable(): boolean {
    return true;
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent): void {
    event.dataTransfer?.setData('text/plain', this.draggedCard);
  }

}
