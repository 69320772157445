import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import { DefaultDecksComponent } from './default-decks/default-decks.component';
import { GraphViewerComponent } from './graph-viewer/graph-viewer.component';
import { GraphEditorRoutingModule } from './graph-editor-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {DeckExplorerModule} from '../deck-explorer/deck-explorer.module';
import { PatternCardComponent } from './pattern-card/pattern-card.component';
import { PatternBodyComponent } from './pattern-body/pattern-body.component';
import {MatCardModule} from '@angular/material/card';
import {DesignRecommenderModule} from '../design-recommender/design-recommender.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { DefaultCardFilterPipe } from './shared/default-card-filter.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DeckSortPipe } from './shared/deck-sort.pipe';
import { PatternAttributionComponent } from './pattern-attribution/pattern-attribution.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { EditorCardFilterPipe } from './shared/editor-card-filter.pipe';
import {MatMenuModule} from '@angular/material/menu';
import { SideNavContentComponent } from './side-nav-content/side-nav-content.component';
import {PatternMenuComponent} from './pattern-menu/pattern-menu.component';
import {PatternConnectionsComponent} from './pattern-connections/pattern-connections.component';
import { CardPatternSmallComponent } from './card-pattern-small/card-pattern-small.component';
import { ConnectionsSortPipe } from './shared/connections-sort.pipe';
import { SvgViewerComponent } from './svg-viewer/svg-viewer.component';
import { DraggablePatternCardDirective } from './shared/draggable-pattern-card.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CustomRelationAddComponent } from '../design-recommender/custom-relation-add/custom-relation-add.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import { PatternMenuCustomComponent } from './pattern-menu-custom/pattern-menu-custom.component';
import { StatusDisplayComponent } from './status-display/status-display.component';
import { StickyNoteComponent } from './sticky-note/sticky-note.component';


@NgModule({
  declarations: [
    EditorComponent,
    DefaultDecksComponent,
    GraphViewerComponent,
    PatternCardComponent,
    PatternBodyComponent,
    DefaultCardFilterPipe,
    DeckSortPipe,
    PatternAttributionComponent,
    EditorCardFilterPipe,
    SideNavContentComponent,
    PatternMenuComponent,
    PatternConnectionsComponent,
    CardPatternSmallComponent,
    ConnectionsSortPipe,
    SvgViewerComponent,
    DraggablePatternCardDirective,
    CustomRelationAddComponent,
    PatternMenuCustomComponent,
    StatusDisplayComponent,
    StickyNoteComponent
  ],
  exports: [
    GraphViewerComponent
  ],
    imports: [
        GraphEditorRoutingModule,
        CommonModule,
        MatTabsModule,
        DeckExplorerModule,
        MatCardModule,
        DesignRecommenderModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatMenuModule,
        DragDropModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatExpansionModule,
        FormsModule
    ]
})
export class GraphEditorModule { }
