import {Component, Input, OnInit} from '@angular/core';
import {DeckType} from '../../shared/card-deck';
import {MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {EditorService} from '../shared/editor.service';
import {CardWebService} from '../../shared/card-web.service';

@Component({
  selector: 'em-pattern-card',
  templateUrl: './pattern-card.component.html',
  styleUrls: ['./pattern-card.component.css']
})
export class PatternCardComponent implements OnInit {
  @Input() cardDeckId: number;
  @Input() cardPatternId: number;
  @Input() cardDeckType: DeckType;
  @Input() rightSidenav: MatSidenav;

  cardPattern$: Observable<CardPattern>;
  buttoncolor;
  constructor(
    private cs: CardStoreService,
    private es: EditorService,
    private cw: CardWebService
  ) {
  }

  ngOnInit(): void {
    this.cardPattern$ = this.cs.getPatternCardWithCell(this.cardDeckId, this.cardPatternId, this.cardDeckType);
    this.buttoncolor = '';
  }

  public openSideMenu(preference: ('left'|'right'|'bottom')) {
    this.cardPattern$.subscribe(pattern => {
      this.es.setConnectionParams(pattern);
    });
    this.rightSidenav.toggle();
    this.cw.setConnectionPreference(preference);
  }
}
