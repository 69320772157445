import {Component, Input, OnInit} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {CardDeck} from '../../shared/card-deck';
import {EditorService} from '../shared/editor.service';
import {CardCell} from '../../shared/card-cell';
import {CardWebService} from '../../shared/card-web.service';
import {CardStoreService} from '../../shared/card-store.service';

@Component({
  selector: 'em-pattern-menu-custom',
  templateUrl: './pattern-menu-custom.component.html',
  styleUrls: ['./pattern-menu-custom.component.css']
})
export class PatternMenuCustomComponent implements OnInit {
  @Input() openCustomConnectionMenu: boolean;

  cardPattern: CardPattern;

  cardDeck: CardDeck;
  customConnectionPatterns: CardPattern[];
  sourceIsNotInCardWeb = false;

  constructor(
    private es: EditorService,
    private cw: CardWebService,
    private cs: CardStoreService) {
  }

  ngOnInit(): void {
    /*this.es.getConncectionParams().subscribe(response => {
       this.customConnectionPatterns = this.cardDeck.cardPatterns;
       this.customConnectionPatterns = this.customConnectionPatterns.filter((item) => {
           this.patternNotInCardCells(item.id);
         }
       );
       console.log(this.customConnectionPatterns);
    });*/
    this.es.getConncectionParams().subscribe(response => {
      this.cardPattern = response;

      this.sourceIsNotInCardWeb = !this.cw.hasCardWebElement(this.cardPattern.id);

      this.cs.getCardDeck(this.cardPattern.cardDeckId).subscribe(deck => {
        this.cardDeck = deck;
      });
    });
  }

  patternNotInCardCells(id: number) {
    return !this.cardPattern.cardCells.some(cell => cell.targetId === id) && !(this.cardPattern.id === id);
  }

  sourceNotInCardWeb(id: number) {
    return !this.cw.hasCardWebElement(id);
  }
}
